import { HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map, take } from 'rxjs';
import { jwtDecode } from 'jwt-decode';

import { ENVIRONMENTS, Environments } from '@emrm/core/environments';
import { SignInResponse, JWTToken } from '@emrm/core/auth/types';
import { ApiService } from '@emrm/core/services/api';
import { LocalStorageService } from '@emrm/core/services/local-storage';
import { UserRoleName } from '@emrm/users/types';

@Injectable({
	providedIn: 'root',
})
export class AuthService {
	private logoutInProcess = false;

	constructor(
		@Inject(ENVIRONMENTS) private readonly environments: Environments,
		private apiService: ApiService,
		private router: Router,
		private storage: LocalStorageService,
	) {}

	login$(login: string, password: string) {
		return this.apiService
			.get$<SignInResponse>(
				`${this.environments.baseUrl}/?user=${login}&password=${password}`,
			)
			.pipe(
				map((response) => {
					this.storage.set('token', response.data.token);
					this.storage.set('access_token', response.data.access_token);
					this.storage.set('refresh_token', response.data.refresh_token);

					let redirectUrl = this.storage.get('redirect_url');

					if (redirectUrl === '/403' || redirectUrl === '/404') {
						redirectUrl = null;
					}

					this.storage.remove('redirect_url');

					return { redirectUrl };
				}),
			);
	}

	logout() {
		if (this.logoutInProcess) {
			return;
		}

		this.logoutInProcess = true;

		const access_token = this.getAccessToken();

		if (!access_token) {
			return;
		}

		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
		});

		this.apiService
			.get$(
				`${this.environments.baseUrl}/logout?access_token=${access_token}`,
				{
					headers,
				},
			)
			.pipe(take(1))
			.subscribe(() => {
				const redirectUrl = this.router.url.startsWith('/auth')
					? '/'
					: this.router.url;

				this.storage.set('redirect_url', redirectUrl);
				this.storage.remove('refresh_token');
				this.storage.remove('access_token');
				this.storage.remove('token');

				this.router.navigate(['/auth']);

				this.logoutInProcess = false;
			});
	}

	isLoggedIn() {
		return !!this.getToken();
	}

	getAccessToken(): string {
		const accessToken = this.storage.get('access_token');
		return accessToken ? accessToken : '';
	}

	getToken(): string {
		const token = this.storage.get('token');
		return token ? token : '';
	}

	getDecodedToken() {
		const token = this.getToken();
		return token ? this.decodeToken(token) : null;
	}

	getUserName() {
		const token = this.getDecodedToken();
		return token && token.full_name ? token.full_name : null;
	}

	getUserRole() {
		const token = this.getDecodedToken();
		return token && token.role ? token.role : null;
	}

	getUserAvatar() {
		const token = this.getDecodedToken();
		return token && token.images ? token.images : '';
	}

	getUserCity() {
		const token = this.getDecodedToken();
		return token && token.city ? token.city : null;
	}

	isAdmin() {
		return this.getUserRole()?.name === UserRoleName.Admin;
	}

	isSuperAdmin() {
		return this.getUserRole()?.name === UserRoleName.SuperAdmin;
	}

	getUserId() {
		const token = this.getDecodedToken();
		return token && token.id ? token.id : null;
	}

	getRiesId() {
		const token = this.getDecodedToken();
		return token && token.ries_id ? token.ries_id : null;
	}

	private decodeToken(token: string) {
		return jwtDecode<JWTToken>(token);
	}
}
