export const UserRoleName = {
	Admin: 'admin',
	SuperAdmin: 'superadmin',
	User: 'user',
} as const;

export type UserRoleName = (typeof UserRoleName)[keyof typeof UserRoleName];
export interface UserRole {
	id: number;
	name: UserRoleName;
	rname: string;
}

export const ALL_ROLES = [
	UserRoleName.SuperAdmin,
	UserRoleName.Admin,
	UserRoleName.User,
];

export const ADMIN_ROLES = [UserRoleName.SuperAdmin, UserRoleName.Admin];

export const USER_ROLES = [UserRoleName.User];
