import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class LocalStorageService {
	get(key: string) {
		try {
			const value = localStorage.getItem(key);

			return value ? JSON.parse(value) : null;
		} catch (e) {
			return null;
		}
	}

	set(key: string, value: unknown) {
		localStorage.setItem(key, JSON.stringify(value));
	}

	remove(key: string) {
		localStorage.removeItem(key);
	}

	clear() {
		localStorage.clear();
	}
}
